<script>
import _ from 'lodash';

export default {
    name: 'GetLocalizedValue',
    methods: {
        getLocalizedValue(field, useEnAsFallback = true) {
            const translatedValue = _.get(field, this.$i18n.locale);

            if (translatedValue) {
                return translatedValue;
            }

            if (useEnAsFallback) {
                return _.get(field, 'en');
            }

            return null;
        }
    }
}
;
</script>
